import customersPageHtml from './controllers/customer/customer-main/customers.html';
import customersDetailsPageHtml from './controllers/customer/customer-details/customers-details.html';
import overviewPageHtml from './controllers/overview/overview.html';
import storePageHtml from './controllers/store/store.html';
import itemsPageHtml from './controllers/grid/items.html';
import metricsPageHtml from './controllers/metrics/metrics.html';
import reportingReportsTabHtml from './controllers/scheduling/tab-reports/tab-reports.html';
import reportingSchedulesTabHtml from './controllers/scheduling/tab-schedules/tab-schedules.html';
import transactionsPageHtml from './controllers/transactions/transactions.html';
import debugMetricsHtml from './controllers/debug/debug-metrics.html';
import adsPageHtml from './controllers/ads/ads.html';

import * as ConfigFlags from './lib/config-flags';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteDefResolveFn<Args extends any[] = any[]> = (...args: Args) => unknown;

export interface Route {
    id: string;
    templates: string[];
    label: string;
    icon?: string;
    oldUrl?: string;
    url: string;
    navigationUrl?: string;
    template: string;
    controller: string;
    group?: string;
    reloadOnSearch?: boolean;
    subLabel?: string;
    active?: boolean;
    resolve?: Record<string, RouteDefResolveFn>;
}
export type Routes = Record<string, Route>;

const getRoutes = (): Routes => {
    const routes: Record<string, Omit<Route, 'id' | 'templates'>> = {
        overview: {
            label: 'Overview',
            icon: 'archive',
            oldUrl: '/reports/sales',
            url: '/reports/overview',
            template: overviewPageHtml,
            controller: 'OverviewController',
            group: 'dashboard',
        },
        metrics: {
            label: 'Metrics',
            icon: 'archive',
            url: '/reports/metrics',
            template: metricsPageHtml,
            controller: 'MetricsController',
        },
        customers: {
            label: 'Customers',
            icon: 'users',
            url: '/reports/customers',
            template: customersPageHtml,
            controller: 'CustomerController',
            group: 'dashboard',
        },
        customerDetails: {
            label: 'Customer Details',
            url: '/reports/customers/:customerId',
            template: customersDetailsPageHtml,
            controller: 'CustomerDetailsController',
            group: 'dashboard',
        },
        chart: {
            label: 'Chart',
            icon: 'house',
            oldUrl: '/reports/stores',
            url: '/reports/chart',
            template: storePageHtml,
            controller: 'StoreController',
            group: 'dashboard',
        },
        storestatus: {
            label: 'Status',
            icon: 'house',
            url: '/status/stores',
            template: '<view-store-status></view-store-status>',
            controller: 'StoreStatusController',
            group: 'dashboard',
        },
        grid: {
            label: 'Grid',
            icon: 'cart',
            oldUrl: '/reports/items',
            url: '/reports/grid',
            template: itemsPageHtml,
            controller: 'ItemsController',
            group: 'dashboard',
            reloadOnSearch: false,
        },
        ads: {
            label: 'Ads',
            icon: 'archive',
            url: '/reports/ads',
            template: adsPageHtml,
            controller: 'AdsController',
            group: 'dashboard',
        },
        transactions: {
            label: 'Transactions',
            icon: 'archive',
            url: '/reports/transactions',
            template: transactionsPageHtml,
            controller: 'TransactionsController',
            group: 'dashboard',
        },
        map: {
            label: 'Map',
            icon: 'map',
            url: '/reports/map',
            template: '<map-view></map-view>',
            controller: 'MapController',
            group: 'dashboard',
            // resolve:
            //     mapData: (MapData) -> MapData.fetch()
        },
        reportingReports: {
            label: 'Reporting',
            subLabel: 'Reports',
            url: '/reporting/reports/:id?',
            navigationUrl: '/reporting/reports',
            template: reportingReportsTabHtml,
            controller: 'ReportingReportsController',
            group: 'reporting',
            resolve: {
                permissions: ($location: angular.ILocationService) => hasReportingPageAccess($location),
            },
        },
        reportingSchedules: {
            label: 'Schedules',
            url: '/reporting/schedules/:id?',
            navigationUrl: '/reporting/schedules',
            template: reportingSchedulesTabHtml,
            controller: 'ReportingSchedulesController',
            group: 'reporting',
            resolve: {
                permissions: ($location: angular.ILocationService) => hasReportingPageAccess($location),
            },
        },
        debugMetrics: {
            label: 'Debug Metrics',
            url: '/debug/metrics',
            template: debugMetricsHtml,
            controller: 'DebugMetricsController',
            group: 'debug',
        },
        counter: {
            label: 'Counter',
            url: '/reports/counter',
            template: '<view-counter model="model"></view-counter>',
            controller: 'CounterController',
            group: 'counter',
        },
    };

    return Object.fromEntries(
        Object.entries(routes).map<[string, Route]>(([id, route]) => {
            const templates = [route.url];
            return [id, { ...route, id, templates }];
        }),
    );
};

const hasReportingPageAccess = async ($location: angular.ILocationService) => {
    try {
        const flags = await ConfigFlags.fetch();
        if (flags.showReporting) return;
        return $location.path('/');
    } catch (error) {
        console.error('[router] error fetching flags:', error);
    }
};

const module = angular.module('42.routes', ['ngRoute']);
module.constant('ROUTES', getRoutes());
